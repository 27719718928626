@use "sass:math";

.nav {
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: block;
    margin: -10px;
    padding: 10px;

    &:focus,
    &:focus-visible,
    &:focus-within {
      outline: 2px solid $accent-color;
    }

    img {
      display: block;
    }
  }

  @media (min-width: $bp-desktop) {
    .nav-menu-overlay,
    .nav-hamburger {
      display: none;
    }
  }

  // Transition adapted from https://codepen.io/RSH87/pen/rmgYbo?editors=1100
  .nav-menu-overlay {
    left: -20px;
    position: fixed;
    top: -20px;
    z-index: 1;

    &:before,
    &:after {
      background: rgba($accent-color, 0.2);
      border-bottom-left-radius: 2em;
      border-bottom-right-radius: 2em;
      content: "";
      height: calc(100vh + 2em + 20px);
      position: fixed;
      transform: translateX(0%) translateY(-100%);
      transition: transform cubic-bezier(0.77, 0, 0.175, 1) 0.8s;
      width: calc(100% + 40px);
      z-index: -1;
    }

    &:after {
      background: rgba($accent-color, 1);
      transition-delay: 0s;
    }

    &:before {
      transition-delay: 0.1s;
    }

    .nav-menu {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      position: fixed;
      text-align: center;
      top: 50vh;
      transform: translate(0%, -50%);
      transition: max-height ease 1s, opacity ease 1s;
      width: calc(100% + 40px);

      .nav-items {
        color: $font-color-accent;
        margin: 0;
        padding: 0;

        li {
          list-style: none;
          position: relative;
          transition-delay: 0.3s;
          opacity: 0;
          transform: translate(0%, 100%);
          transition: opacity 0.2s ease, transform 0.3s ease;

          a {
            color: inherit;
            font-size: 1.5em;
            padding: 0.5em 0;
            text-decoration: none;
            transition: background-color 0.3s ease;

            @media (min-width: $bp-tablet) {
              padding: 0.8em 0;
            }

            &:hover {
              background-color: $secondary-accent-color;
            }
          }

          &:last-child a {
            color: $white;
            font-family: $font-bold;
          }
        }
      }
    }

    &.active {
      visibility: visible;

      &:before,
      &:after {
        transform: translateX(0%) translateY(0%);
      }

      &:after {
        transition-delay: 0.1s;
      }

      &:before {
        transition-delay: 0s;
      }

      .nav-menu {
        max-height: 100%;
        opacity: 1;
        overflow: visible;

        .nav-items li {
          opacity: 1;
          transform: translateX(0%);
          transition: opacity 0.3s ease, transform 0.3s ease;

          @for $i from 0 through 5 {
            &:nth-child(#{$i}) {
              transition-delay: math.div(0.3 * $i, 4) + 0.4 + s;
            }
          }
        }
      }

      & + .nav-hamburger {
        .line {
          background-color: $font-color-accent;
        }

        .line-1 {
          top: -2px;
          transform: rotateZ(45deg);
        }

        .line-2 {
          right: 4px;
          top: 0;
          transform: rotateZ(-45deg);
          width: 28px;
        }

        .line-3 {
          top: 2px;
          transform: rotateZ(45deg);
        }
      }
    }
  }

  // Hamburger adapted from https://codepen.io/himalayasingh/pen/mNOJPM?editors=1100
  .nav-hamburger {
    height: 40px;
    position: relative;
    width: 40px;
    z-index: 2;

    .line {
      background-color: $heading-color;
      height: 4px;
      margin-bottom: 4px;
      position: relative;
      top: 0;
      transition: 0.3s ease transform, 0.3s ease top, 0.3s ease width,
        0.3s ease right, 0.3s ease background-color 0.4s;
    }

    .line-1 {
      transform-origin: 0;
    }

    .line-2 {
      right: 0;
      width: 20px;
    }

    .line-3 {
      margin-bottom: 0;
      transform-origin: 20px;
    }
  }

  .nav-menu-desktop {
    display: none;

    .nav-items {
      display: flex;
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        position: relative;

        a {
          border-radius: 0.3em;
          color: $font-color;
          font-weight: 500;
          padding: 20px 12px;
          text-decoration: none;

          &:hover {
            color: $secondary-accent-color;
            text-decoration: underline;
          }
        }

        &:last-child a {
          background-color: $accent-color;
          color: $white;
          font-family: $font-bold;
          margin-left: 12px;
          padding: 20px 25px;
          transition: background-color 0.3s ease;

          &:hover {
            background-color: $secondary-accent-color;
            text-decoration: none;
          }
        }
      }
    }

    @media (min-width: $bp-desktop) {
      display: block;
    }
  }
}
