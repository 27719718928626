*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  background-color: $white;
  color: $font-color;
  font-family: $font-regular;
  font-size: 17px;
  line-height: 1.5em;

  &.disable-scroll {
    overflow: hidden;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $heading-color;
  font-family: $font-bold;
  margin: 0;
}

.sr-only {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}
